/*====================================================

@File: Default Styles
@Author: Grupo Gamma Peninsular
@URL: http://ggamma.com
Author E-mail: info@ggamma.com

---------------------------------------------------- */

/*=====================================================================

@Default Styles

Table of Content:
01/ colors
02/ Boostrap
03/ predefin
04/ colorsClasses
05/ helpers
06/ animations

---------------------------------------------------------*/

/*----------------------- 01 ----------------------------*/

@import "colors";

/*------------------------------------------------------ */

/*----------------------- 02 ----------------------------*/

@import "../components/bootstrap/scss/bootstrap.scss";

/*------------------------------------------------------ */

/*----------------------- 03 ----------------------------*/

@import "predefin";

/*------------------------------------------------------ */

/*----------------------- 04 ----------------------------*/

@import "colorsClasses";

/*------------------------------------------------------ */

/*----------------------- 05 ----------------------------*/

@import "helpers";

/*------------------------------------------------------ */

/*----------------------- 06 ----------------------------*/

@import "animate";

/*------------------------------------------------------ */

/*----------------------- 07 ----------------------------*/

@import "header";

/*------------------------------------------------------ */

/*----------------------- 08 ----------------------------*/

@import "footer";

/*------------------------------------------------------ */

/*----------------------- 09 ----------------------------*/

@import "home";

/*------------------------------------------------------ */