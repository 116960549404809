
.bg-contain { 
    position: relative; 
}

.bg-image {
    background-position: center;
    background-repeat: no-repeat;
    &.cover {
        background-size: cover;
    }
    &.contain {
        background-size: contain;
    }
}

.img-sprite {
    background-position: left top;
    background-repeat: no-repeat;
    &:hover {
        background-position: left bottom;
    }
}

.underline {
    text-decoration: underline !important;
}

.no-underline {
    text-decoration: none !important;
}

.wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px;
}

.h-window {
    min-height: 100vh;
}

.w-window {
    min-width: 100vw;
}