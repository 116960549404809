
///*------------------------------------*\
//    #TEXT COLOR
//\*------------------------------------*/

/** 50 **/
.tc-red-50 {   
    color: $red-50 !important;
}
.tc-pink-50 {   
    color: $pink-50 !important;
}
.tc-purple-50 {   
    color: $purple-50 !important;
}
.tc-deep-purple-50 {   
    color: $deep-purple-50 !important;
}
.tc-indigo-50 {   
    color: $indigo-50 !important;
}
.tc-blue-50 {   
    color: $blue-50 !important;
}
.tc-light-blue-50 {   
    color: $light-blue-50 !important;
}
.tc-cyan-50 {   
    color: $cyan-50 !important;
}
.tc-teal-50 {   
    color: $teal-50 !important;
}
.tc-green-50 {   
    color: $green-50 !important;
}
.tc-light-green-50 {   
    color: $light-green-50 !important;
}
.tc-lime-50 {   
    color: $lime-50 !important;
}
.tc-yellow-50 {   
    color: $yellow-50 !important;
}
.tc-amber-50 {   
    color: $amber-50 !important;
}
.tc-orange-50 {   
    color: $orange-50 !important;
}
.tc-deep-orange-50 {   
    color: $deep-orange-50 !important;
}
.tc-brown-50 {   
    color: $brown-50 !important;
}
.tc-grey-50 {   
    color: $grey-50 !important;
}
.tc-blue-grey-50 {   
    color: $blue-grey-50 !important;
}

/** 100 **/
.tc-red-100 {   
    color: $red-100 !important;
}
.tc-pink-100 {   
    color: $pink-100 !important;
}
.tc-purple-100 {   
    color: $purple-100 !important;
}
.tc-deep-purple-100 {   
    color: $deep-purple-100 !important;
}
.tc-indigo-100 {   
    color: $indigo-100 !important;
}
.tc-blue-100 {   
    color: $blue-100 !important;
}
.tc-light-blue-100 {   
    color: $light-blue-100 !important;
}
.tc-cyan-100 {   
    color: $cyan-100 !important;
}
.tc-teal-100 {   
    color: $teal-100 !important;
}
.tc-green-100 {   
    color: $green-100 !important;
}
.tc-light-green-100 {   
    color: $light-green-100 !important;
}
.tc-lime-100 {   
    color: $lime-100 !important;
}
.tc-yellow-100 {   
    color: $yellow-100 !important;
}
.tc-amber-100 {   
    color: $amber-100 !important;
}
.tc-orange-100 {   
    color: $orange-100 !important;
}
.tc-deep-orange-100 {   
    color: $deep-orange-100 !important;
}
.tc-brown-100 {   
    color: $brown-100 !important;
}
.tc-grey-100 {   
    color: $grey-100 !important;
}
.tc-blue-grey-100 {   
    color: $blue-grey-100 !important;
}

/** 200 **/
.tc-red-200 {   
    color: $red-200 !important;
}
.tc-pink-200 {   
    color: $pink-200 !important;
}
.tc-purple-200 {   
    color: $purple-200 !important;
}
.tc-deep-purple-200 {   
    color: $deep-purple-200 !important;
}
.tc-indigo-200 {   
    color: $indigo-200 !important;
}
.tc-blue-200 {   
    color: $blue-200 !important;
}
.tc-light-blue-200 {   
    color: $light-blue-200 !important;
}
.tc-cyan-200 {   
    color: $cyan-200 !important;
}
.tc-teal-200 {   
    color: $teal-200 !important;
}
.tc-green-200 {   
    color: $green-200 !important;
}
.tc-light-green-200 {   
    color: $light-green-200 !important;
}
.tc-lime-200 {   
    color: $lime-200 !important;
}
.tc-yellow-200 {   
    color: $yellow-200 !important;
}
.tc-amber-200 {   
    color: $amber-200 !important;
}
.tc-orange-200 {   
    color: $orange-200 !important;
}
.tc-deep-orange-200 {   
    color: $deep-orange-200 !important;
}
.tc-brown-200 {   
    color: $brown-200 !important;
}
.tc-grey-200 {   
    color: $grey-200 !important;
}
.tc-blue-grey-200 {   
    color: $blue-grey-200 !important;
}

/** 300 **/
.tc-red-300 {   
    color: $red-300 !important;
}
.tc-pink-300 {   
    color: $pink-300 !important;
}
.tc-purple-300 {   
    color: $purple-300 !important;
}
.tc-deep-purple-300 {   
    color: $deep-purple-300 !important;
}
.tc-indigo-300 {   
    color: $indigo-300 !important;
}
.tc-blue-300 {   
    color: $blue-300 !important;
}
.tc-light-blue-300 {   
    color: $light-blue-300 !important;
}
.tc-cyan-300 {   
    color: $cyan-300 !important;
}
.tc-teal-300 {   
    color: $teal-300 !important;
}
.tc-green-300 {   
    color: $green-300 !important;
}
.tc-light-green-300 {   
    color: $light-green-300 !important;
}
.tc-lime-300 {   
    color: $lime-300 !important;
}
.tc-yellow-300 {   
    color: $yellow-300 !important;
}
.tc-amber-300 {   
    color: $amber-300 !important;
}
.tc-orange-300 {   
    color: $orange-300 !important;
}
.tc-deep-orange-300 {   
    color: $deep-orange-300 !important;
}
.tc-brown-300 {   
    color: $brown-300 !important;
}
.tc-grey-300 {   
    color: $grey-300 !important;
}
.tc-blue-grey-300 {   
    color: $blue-grey-300 !important;
}

/** 400 **/
.tc-red-400 {   
    color: $red-400 !important;
}
.tc-pink-400 {   
    color: $pink-400 !important;
}
.tc-purple-400 {   
    color: $purple-400 !important;
}
.tc-deep-purple-400 {   
    color: $deep-purple-400 !important;
}
.tc-indigo-400 {   
    color: $indigo-400 !important;
}
.tc-blue-400 {   
    color: $blue-400 !important;
}
.tc-light-blue-400 {   
    color: $light-blue-400 !important;
}
.tc-cyan-400 {   
    color: $cyan-400 !important;
}
.tc-teal-400 {   
    color: $teal-400 !important;
}
.tc-green-400 {   
    color: $green-400 !important;
}
.tc-light-green-400 {   
    color: $light-green-400 !important;
}
.tc-lime-400 {   
    color: $lime-400 !important;
}
.tc-yellow-400 {   
    color: $yellow-400 !important;
}
.tc-amber-400 {   
    color: $amber-400 !important;
}
.tc-orange-400 {   
    color: $orange-400 !important;
}
.tc-deep-orange-400 {   
    color: $deep-orange-400 !important;
}
.tc-brown-400 {   
    color: $brown-400 !important;
}
.tc-grey-400 {   
    color: $grey-400 !important;
}
.tc-blue-grey-400 {   
    color: $blue-grey-400 !important;
}

/** 500 **/
.tc-red-500 {   
    color: $red-500 !important;
}
.tc-pink-500 {   
    color: $pink-500 !important;
}
.tc-purple-500 {   
    color: $purple-500 !important;
}
.tc-deep-purple-500 {   
    color: $deep-purple-500 !important;
}
.tc-indigo-500 {   
    color: $indigo-500 !important;
}
.tc-blue-500 {   
    color: $blue-500 !important;
}
.tc-light-blue-500 {   
    color: $light-blue-500 !important;
}
.tc-cyan-500 {   
    color: $cyan-500 !important;
}
.tc-teal-500 {   
    color: $teal-500 !important;
}
.tc-green-500 {   
    color: $green-500 !important;
}
.tc-light-green-500 {   
    color: $light-green-500 !important;
}
.tc-lime-500 {   
    color: $lime-500 !important;
}
.tc-yellow-500 {   
    color: $yellow-500 !important;
}
.tc-amber-500 {   
    color: $amber-500 !important;
}
.tc-orange-500 {   
    color: $orange-500 !important;
}
.tc-deep-orange-500 {   
    color: $deep-orange-500 !important;
}
.tc-brown-500 {   
    color: $brown-500 !important;
}
.tc-grey-500 {   
    color: $grey-500 !important;
}
.tc-blue-grey-500 {   
    color: $blue-grey-500 !important;
}

/** 600 **/
.tc-red-600 {   
    color: $red-600 !important;
}
.tc-pink-600 {   
    color: $pink-600 !important;
}
.tc-purple-600 {   
    color: $purple-600 !important;
}
.tc-deep-purple-600 {   
    color: $deep-purple-600 !important;
}
.tc-indigo-600 {   
    color: $indigo-600 !important;
}
.tc-blue-600 {   
    color: $blue-600 !important;
}
.tc-light-blue-600 {   
    color: $light-blue-600 !important;
}
.tc-cyan-600 {   
    color: $cyan-600 !important;
}
.tc-teal-600 {   
    color: $teal-600 !important;
}
.tc-green-600 {   
    color: $green-600 !important;
}
.tc-light-green-600 {   
    color: $light-green-600 !important;
}
.tc-lime-600 {   
    color: $lime-600 !important;
}
.tc-yellow-600 {   
    color: $yellow-600 !important;
}
.tc-amber-600 {   
    color: $amber-600 !important;
}
.tc-orange-600 {   
    color: $orange-600 !important;
}
.tc-deep-orange-600 {   
    color: $deep-orange-600 !important;
}
.tc-brown-600 {   
    color: $brown-600 !important;
}
.tc-grey-600 {   
    color: $grey-600 !important;
}
.tc-blue-grey-600 {   
    color: $blue-grey-600 !important;
}

/** 700 **/
.tc-red-700 {   
    color: $red-700 !important;
}
.tc-pink-700 {   
    color: $pink-700 !important;
}
.tc-purple-700 {   
    color: $purple-700 !important;
}
.tc-deep-purple-700 {   
    color: $deep-purple-700 !important;
}
.tc-indigo-700 {   
    color: $indigo-700 !important;
}
.tc-blue-700 {   
    color: $blue-700 !important;
}
.tc-light-blue-700 {   
    color: $light-blue-700 !important;
}
.tc-cyan-700 {   
    color: $cyan-700 !important;
}
.tc-teal-700 {   
    color: $teal-700 !important;
}
.tc-green-700 {   
    color: $green-700 !important;
}
.tc-light-green-700 {   
    color: $light-green-700 !important;
}
.tc-lime-700 {   
    color: $lime-700 !important;
}
.tc-yellow-700 {   
    color: $yellow-700 !important;
}
.tc-amber-700 {   
    color: $amber-700 !important;
}
.tc-orange-700 {   
    color: $orange-700 !important;
}
.tc-deep-orange-700 {   
    color: $deep-orange-700 !important;
}
.tc-brown-700 {   
    color: $brown-700 !important;
}
.tc-grey-700 {   
    color: $grey-700 !important;
}
.tc-blue-grey-700 {   
    color: $blue-grey-700 !important;
}

/** 800 **/
.tc-red-800 {   
    color: $red-800 !important;
}
.tc-pink-800 {   
    color: $pink-800 !important;
}
.tc-purple-800 {   
    color: $purple-800 !important;
}
.tc-deep-purple-800 {   
    color: $deep-purple-800 !important;
}
.tc-indigo-800 {   
    color: $indigo-800 !important;
}
.tc-blue-800 {   
    color: $blue-800 !important;
}
.tc-light-blue-800 {   
    color: $light-blue-800 !important;
}
.tc-cyan-800 {   
    color: $cyan-800 !important;
}
.tc-teal-800 {   
    color: $teal-800 !important;
}
.tc-green-800 {   
    color: $green-800 !important;
}
.tc-light-green-800 {   
    color: $light-green-800 !important;
}
.tc-lime-800 {   
    color: $lime-800 !important;
}
.tc-yellow-800 {   
    color: $yellow-800 !important;
}
.tc-amber-800 {   
    color: $amber-800 !important;
}
.tc-orange-800 {   
    color: $orange-800 !important;
}
.tc-deep-orange-800 {   
    color: $deep-orange-800 !important;
}
.tc-brown-800 {   
    color: $brown-800 !important;
}
.tc-grey-800 {   
    color: $grey-800 !important;
}
.tc-blue-grey-800 {   
    color: $blue-grey-800 !important;
}

/** 900 **/
.tc-red-900 {   
    color: $red-900 !important;
}
.tc-pink-900 {   
    color: $pink-900 !important;
}
.tc-purple-900 {   
    color: $purple-900 !important;
}
.tc-deep-purple-900 {   
    color: $deep-purple-900 !important;
}
.tc-indigo-900 {   
    color: $indigo-900 !important;
}
.tc-blue-900 {   
    color: $blue-900 !important;
}
.tc-light-blue-900 {   
    color: $light-blue-900 !important;
}
.tc-cyan-900 {   
    color: $cyan-900 !important;
}
.tc-teal-900 {   
    color: $teal-900 !important;
}
.tc-green-900 {   
    color: $green-900 !important;
}
.tc-light-green-900 {   
    color: $light-green-900 !important;
}
.tc-lime-900 {   
    color: $lime-900 !important;
}
.tc-yellow-900 {   
    color: $yellow-900 !important;
}
.tc-amber-900 {   
    color: $amber-900 !important;
}
.tc-orange-900 {   
    color: $orange-900 !important;
}
.tc-deep-orange-900 {   
    color: $deep-orange-900 !important;
}
.tc-brown-900 {   
    color: $brown-900 !important;
}
.tc-grey-900 {   
    color: $grey-900 !important;
}
.tc-blue-grey-900 {   
    color: $blue-grey-900 !important;
}

/** A100 **/
.tc-red-A100 {   
    color: $red-A100 !important;
}
.tc-pink-A100 {   
    color: $pink-A100 !important;
}
.tc-purple-A100 {   
    color: $purple-A100 !important;
}
.tc-deep-purple-A100 {   
    color: $deep-purple-A100 !important;
}
.tc-indigo-A100 {   
    color: $indigo-A100 !important;
}
.tc-blue-A100 {   
    color: $blue-A100 !important;
}
.tc-light-blue-A100 {   
    color: $light-blue-A100 !important;
}
.tc-cyan-A100 {   
    color: $cyan-A100 !important;
}
.tc-teal-A100 {   
    color: $teal-A100 !important;
}
.tc-green-A100 {   
    color: $green-A100 !important;
}
.tc-light-green-A100 {   
    color: $light-green-A100 !important;
}
.tc-lime-A100 {   
    color: $lime-A100 !important;
}
.tc-yellow-A100 {   
    color: $yellow-A100 !important;
}
.tc-amber-A100 {   
    color: $amber-A100 !important;
}
.tc-orange-A100 {   
    color: $orange-A100 !important;
}
.tc-deep-orange-A100 {   
    color: $deep-orange-A100 !important;
}

/** A200 **/
.tc-red-A200 {   
    color: $red-A200 !important;
}
.tc-pink-A200 {   
    color: $pink-A200 !important;
}
.tc-purple-A200 {   
    color: $purple-A200 !important;
}
.tc-deep-purple-A200 {   
    color: $deep-purple-A200 !important;
}
.tc-indigo-A200 {   
    color: $indigo-A200 !important;
}
.tc-blue-A200 {   
    color: $blue-A200 !important;
}
.tc-light-blue-A200 {   
    color: $light-blue-A200 !important;
}
.tc-cyan-A200 {   
    color: $cyan-A200 !important;
}
.tc-teal-A200 {   
    color: $teal-A200 !important;
}
.tc-green-A200 {   
    color: $green-A200 !important;
}
.tc-light-green-A200 {   
    color: $light-green-A200 !important;
}
.tc-lime-A200 {   
    color: $lime-A200 !important;
}
.tc-yellow-A200 {   
    color: $yellow-A200 !important;
}
.tc-amber-A200 {   
    color: $amber-A200 !important;
}
.tc-orange-A200 {   
    color: $orange-A200 !important;
}
.tc-deep-orange-A200 {   
    color: $deep-orange-A200 !important;
}

/** A400 **/
.tc-red-A400 {   
    color: $red-A400 !important;
}
.tc-pink-A400 {   
    color: $pink-A400 !important;
}
.tc-purple-A400 {   
    color: $purple-A400 !important;
}
.tc-deep-purple-A400 {   
    color: $deep-purple-A400 !important;
}
.tc-indigo-A400 {   
    color: $indigo-A400 !important;
}
.tc-blue-A400 {   
    color: $blue-A400 !important;
}
.tc-light-blue-A400 {   
    color: $light-blue-A400 !important;
}
.tc-cyan-A400 {   
    color: $cyan-A400 !important;
}
.tc-teal-A400 {   
    color: $teal-A400 !important;
}
.tc-green-A400 {   
    color: $green-A400 !important;
}
.tc-light-green-A400 {   
    color: $light-green-A400 !important;
}
.tc-lime-A400 {   
    color: $lime-A400 !important;
}
.tc-yellow-A400 {   
    color: $yellow-A400 !important;
}
.tc-amber-A400 {   
    color: $amber-A400 !important;
}
.tc-orange-A400 {   
    color: $orange-A400 !important;
}
.tc-deep-orange-A400 {   
    color: $deep-orange-A400 !important;
}

/** A700 **/
.tc-red-A700 {   
    color: $red-A700 !important;
}
.tc-pink-A700 {   
    color: $pink-A700 !important;
}
.tc-purple-A700 {   
    color: $purple-A700 !important;
}
.tc-deep-purple-A700 {   
    color: $deep-purple-A700 !important;
}
.tc-indigo-A700 {   
    color: $indigo-A700 !important;
}
.tc-blue-A700 {   
    color: $blue-A700 !important;
}
.tc-light-blue-A700 {   
    color: $light-blue-A700 !important;
}
.tc-cyan-A700 {   
    color: $cyan-A700 !important;
}
.tc-teal-A700 {   
    color: $teal-A700 !important;
}
.tc-green-A700 {   
    color: $green-A700 !important;
}
.tc-light-green-A700 {   
    color: $light-green-A700 !important;
}
.tc-lime-A700 {   
    color: $lime-A700 !important;
}
.tc-yellow-A700 {   
    color: $yellow-A700 !important;
}
.tc-amber-A700 {   
    color: $amber-A700 !important;
}
.tc-orange-A700 {   
    color: $orange-A700 !important;
}
.tc-deep-orange-A700 {   
    color: $deep-orange-A700 !important;
}

/** Black **/
.tc-black {
    color: $black !important; 
}
.tc-black-1 {
    color: $black-1 !important; 
}
.tc-black-2 {
    color: $black-2 !important; 
}
.tc-black-3 {
    color: $black-3 !important; 
}
.tc-black-4 {
    color: $black-4 !important; 
}

/** White **/
.tc-white {
    color: $white !important; 
}
.tc-white-1 {
    color: $white-1 !important; 
}
.tc-white-2 {
    color: $white-2 !important; 
}
.tc-white-3 {
    color: $white-3 !important; 
}
.tc-white-4 {
    color: $white-4 !important; 
}

///*------------------------------------*\
//    #BACKGROUND COLOR
//\*------------------------------------*/

/** 50 **/
.bgc-red-50 {
    background-color: $red-50 !important;
}
.bgc-pink-50 {
    background-color: $pink-50 !important;
}
.bgc-purple-50 {
    background-color: $purple-50 !important;
}
.bgc-deep-purple-50 {
    background-color: $deep-purple-50 !important;
}
.bgc-indigo-50 {
    background-color: $indigo-50 !important;
}
.bgc-blue-50 {
    background-color: $blue-50 !important;
}
.bgc-light-blue-50 {
    background-color: $light-blue-50 !important;
}
.bgc-cyan-50 {
    background-color: $cyan-50 !important;
}
.bgc-teal-50 {
    background-color: $teal-50 !important;
}
.bgc-green-50 {
    background-color: $green-50 !important;
}
.bgc-light-green-50 {
    background-color: $light-green-50 !important;
}
.bgc-lime-50 {
    background-color: $lime-50 !important;
}
.bgc-yellow-50 {
    background-color: $yellow-50 !important;
}
.bgc-amber-50 {
    background-color: $amber-50 !important;
}
.bgc-orange-50 {
    background-color: $orange-50 !important;
}
.bgc-deep-orange-50 {
    background-color: $deep-orange-50 !important;
}
.bgc-brown-50 {
    background-color: $brown-50 !important;
}
.bgc-grey-50 {
    background-color: $grey-50 !important;
}
.bgc-blue-grey-50 {
    background-color: $blue-grey-50 !important;
}

/** 100 **/
.bgc-red-100 {
    background-color: $red-100 !important;
}
.bgc-pink-100 {
    background-color: $pink-100 !important;
}
.bgc-purple-100 {
    background-color: $purple-100 !important;
}
.bgc-deep-purple-100 {
    background-color: $deep-purple-100 !important;
}
.bgc-indigo-100 {
    background-color: $indigo-100 !important;
}
.bgc-blue-100 {
    background-color: $blue-100 !important;
}
.bgc-light-blue-100 {
    background-color: $light-blue-100 !important;
}
.bgc-cyan-100 {
    background-color: $cyan-100 !important;
}
.bgc-teal-100 {
    background-color: $teal-100 !important;
}
.bgc-green-100 {
    background-color: $green-100 !important;
}
.bgc-light-green-100 {
    background-color: $light-green-100 !important;
}
.bgc-lime-100 {
    background-color: $lime-100 !important;
}
.bgc-yellow-100 {
    background-color: $yellow-100 !important;
}
.bgc-amber-100 {
    background-color: $amber-100 !important;
}
.bgc-orange-100 {
    background-color: $orange-100 !important;
}
.bgc-deep-orange-100 {
    background-color: $deep-orange-100 !important;
}
.bgc-brown-100 {
    background-color: $brown-100 !important;
}
.bgc-grey-100 {
    background-color: $grey-100 !important;
}
.bgc-blue-grey-100 {
    background-color: $blue-grey-100 !important;
}

/** 200 **/
.bgc-red-200 {
    background-color: $red-200 !important;
}
.bgc-pink-200 {
    background-color: $pink-200 !important;
}
.bgc-purple-200 {
    background-color: $purple-200 !important;
}
.bgc-deep-purple-200 {
    background-color: $deep-purple-200 !important;
}
.bgc-indigo-200 {
    background-color: $indigo-200 !important;
}
.bgc-blue-200 {
    background-color: $blue-200 !important;
}
.bgc-light-blue-200 {
    background-color: $light-blue-200 !important;
}
.bgc-cyan-200 {
    background-color: $cyan-200 !important;
}
.bgc-teal-200 {
    background-color: $teal-200 !important;
}
.bgc-green-200 {
    background-color: $green-200 !important;
}
.bgc-light-green-200 {
    background-color: $light-green-200 !important;
}
.bgc-lime-200 {
    background-color: $lime-200 !important;
}
.bgc-yellow-200 {
    background-color: $yellow-200 !important;
}
.bgc-amber-200 {
    background-color: $amber-200 !important;
}
.bgc-orange-200 {
    background-color: $orange-200 !important;
}
.bgc-deep-orange-200 {
    background-color: $deep-orange-200 !important;
}
.bgc-brown-200 {
    background-color: $brown-200 !important;
}
.bgc-grey-200 {
    background-color: $grey-200 !important;
}
.bgc-blue-grey-200 {
    background-color: $blue-grey-200 !important;
}

/** 300 **/
.bgc-red-300 {
    background-color: $red-300 !important;
}
.bgc-pink-300 {
    background-color: $pink-300 !important;
}
.bgc-purple-300 {
    background-color: $purple-300 !important;
}
.bgc-deep-purple-300 {
    background-color: $deep-purple-300 !important;
}
.bgc-indigo-300 {
    background-color: $indigo-300 !important;
}
.bgc-blue-300 {
    background-color: $blue-300 !important;
}
.bgc-light-blue-300 {
    background-color: $light-blue-300 !important;
}
.bgc-cyan-300 {
    background-color: $cyan-300 !important;
}
.bgc-teal-300 {
    background-color: $teal-300 !important;
}
.bgc-green-300 {
    background-color: $green-300 !important;
}
.bgc-light-green-300 {
    background-color: $light-green-300 !important;
}
.bgc-lime-300 {
    background-color: $lime-300 !important;
}
.bgc-yellow-300 {
    background-color: $yellow-300 !important;
}
.bgc-amber-300 {
    background-color: $amber-300 !important;
}
.bgc-orange-300 {
    background-color: $orange-300 !important;
}
.bgc-deep-orange-300 {
    background-color: $deep-orange-300 !important;
}
.bgc-brown-300 {
    background-color: $brown-300 !important;
}
.bgc-grey-300 {
    background-color: $grey-300 !important;
}
.bgc-blue-grey-300 {
    background-color: $blue-grey-300 !important;
}

/** 400 **/
.bgc-red-400 {
    background-color: $red-400 !important;
}
.bgc-pink-400 {
    background-color: $pink-400 !important;
}
.bgc-purple-400 {
    background-color: $purple-400 !important;
}
.bgc-deep-purple-400 {
    background-color: $deep-purple-400 !important;
}
.bgc-indigo-400 {
    background-color: $indigo-400 !important;
}
.bgc-blue-400 {
    background-color: $blue-400 !important;
}
.bgc-light-blue-400 {
    background-color: $light-blue-400 !important;
}
.bgc-cyan-400 {
    background-color: $cyan-400 !important;
}
.bgc-teal-400 {
    background-color: $teal-400 !important;
}
.bgc-green-400 {
    background-color: $green-400 !important;
}
.bgc-light-green-400 {
    background-color: $light-green-400 !important;
}
.bgc-lime-400 {
    background-color: $lime-400 !important;
}
.bgc-yellow-400 {
    background-color: $yellow-400 !important;
}
.bgc-amber-400 {
    background-color: $amber-400 !important;
}
.bgc-orange-400 {
    background-color: $orange-400 !important;
}
.bgc-deep-orange-400 {
    background-color: $deep-orange-400 !important;
}
.bgc-brown-400 {
    background-color: $brown-400 !important;
}
.bgc-grey-400 {
    background-color: $grey-400 !important;
}
.bgc-blue-grey-400 {
    background-color: $blue-grey-400 !important;
}

/** 500 **/
.bgc-red-500 {
    background-color: $red-500 !important;
}
.bgc-pink-500 {
    background-color: $pink-500 !important;
}
.bgc-purple-500 {
    background-color: $purple-500 !important;
}
.bgc-deep-purple-500 {
    background-color: $deep-purple-500 !important;
}
.bgc-indigo-500 {
    background-color: $indigo-500 !important;
}
.bgc-blue-500 {
    background-color: $blue-500 !important;
}
.bgc-light-blue-500 {
    background-color: $light-blue-500 !important;
}
.bgc-cyan-500 {
    background-color: $cyan-500 !important;
}
.bgc-teal-500 {
    background-color: $teal-500 !important;
}
.bgc-green-500 {
    background-color: $green-500 !important;
}
.bgc-light-green-500 {
    background-color: $light-green-500 !important;
}
.bgc-lime-500 {
    background-color: $lime-500 !important;
}
.bgc-yellow-500 {
    background-color: $yellow-500 !important;
}
.bgc-amber-500 {
    background-color: $amber-500 !important;
}
.bgc-orange-500 {
    background-color: $orange-500 !important;
}
.bgc-deep-orange-500 {
    background-color: $deep-orange-500 !important;
}
.bgc-brown-500 {
    background-color: $brown-500 !important;
}
.bgc-grey-500 {
    background-color: $grey-500 !important;
}
.bgc-blue-grey-500 {
    background-color: $blue-grey-500 !important;
}

/** 600 **/
.bgc-red-600 {
    background-color: $red-600 !important;
}
.bgc-pink-600 {
    background-color: $pink-600 !important;
}
.bgc-purple-600 {
    background-color: $purple-600 !important;
}
.bgc-deep-purple-600 {
    background-color: $deep-purple-600 !important;
}
.bgc-indigo-600 {
    background-color: $indigo-600 !important;
}
.bgc-blue-600 {
    background-color: $blue-600 !important;
}
.bgc-light-blue-600 {
    background-color: $light-blue-600 !important;
}
.bgc-cyan-600 {
    background-color: $cyan-600 !important;
}
.bgc-teal-600 {
    background-color: $teal-600 !important;
}
.bgc-green-600 {
    background-color: $green-600 !important;
}
.bgc-light-green-600 {
    background-color: $light-green-600 !important;
}
.bgc-lime-600 {
    background-color: $lime-600 !important;
}
.bgc-yellow-600 {
    background-color: $yellow-600 !important;
}
.bgc-amber-600 {
    background-color: $amber-600 !important;
}
.bgc-orange-600 {
    background-color: $orange-600 !important;
}
.bgc-deep-orange-600 {
    background-color: $deep-orange-600 !important;
}
.bgc-brown-600 {
    background-color: $brown-600 !important;
}
.bgc-grey-600 {
    background-color: $grey-600 !important;
}
.bgc-blue-grey-600 {
    background-color: $blue-grey-600 !important;
}

/** 700 **/
.bgc-red-700 {
    background-color: $red-700 !important;
}
.bgc-pink-700 {
    background-color: $pink-700 !important;
}
.bgc-purple-700 {
    background-color: $purple-700 !important;
}
.bgc-deep-purple-700 {
    background-color: $deep-purple-700 !important;
}
.bgc-indigo-700 {
    background-color: $indigo-700 !important;
}
.bgc-blue-700 {
    background-color: $blue-700 !important;
}
.bgc-light-blue-700 {
    background-color: $light-blue-700 !important;
}
.bgc-cyan-700 {
    background-color: $cyan-700 !important;
}
.bgc-teal-700 {
    background-color: $teal-700 !important;
}
.bgc-green-700 {
    background-color: $green-700 !important;
}
.bgc-light-green-700 {
    background-color: $light-green-700 !important;
}
.bgc-lime-700 {
    background-color: $lime-700 !important;
}
.bgc-yellow-700 {
    background-color: $yellow-700 !important;
}
.bgc-amber-700 {
    background-color: $amber-700 !important;
}
.bgc-orange-700 {
    background-color: $orange-700 !important;
}
.bgc-deep-orange-700 {
    background-color: $deep-orange-700 !important;
}
.bgc-brown-700 {
    background-color: $brown-700 !important;
}
.bgc-grey-700 {
    background-color: $grey-700 !important;
}
.bgc-blue-grey-700 {
    background-color: $blue-grey-700 !important;
}

/** 800 **/
.bgc-red-800 {
    background-color: $red-800 !important;
}
.bgc-pink-800 {
    background-color: $pink-800 !important;
}
.bgc-purple-800 {
    background-color: $purple-800 !important;
}
.bgc-deep-purple-800 {
    background-color: $deep-purple-800 !important;
}
.bgc-indigo-800 {
    background-color: $indigo-800 !important;
}
.bgc-blue-800 {
    background-color: $blue-800 !important;
}
.bgc-light-blue-800 {
    background-color: $light-blue-800 !important;
}
.bgc-cyan-800 {
    background-color: $cyan-800 !important;
}
.bgc-teal-800 {
    background-color: $teal-800 !important;
}
.bgc-green-800 {
    background-color: $green-800 !important;
}
.bgc-light-green-800 {
    background-color: $light-green-800 !important;
}
.bgc-lime-800 {
    background-color: $lime-800 !important;
}
.bgc-yellow-800 {
    background-color: $yellow-800 !important;
}
.bgc-amber-800 {
    background-color: $amber-800 !important;
}
.bgc-orange-800 {
    background-color: $orange-800 !important;
}
.bgc-deep-orange-800 {
    background-color: $deep-orange-800 !important;
}
.bgc-brown-800 {
    background-color: $brown-800 !important;
}
.bgc-grey-800 {
    background-color: $grey-800 !important;
}
.bgc-blue-grey-800 {
    background-color: $blue-grey-800 !important;
}

/** 900 **/
.bgc-red-900 {
    background-color: $red-900 !important;
}
.bgc-pink-900 {
    background-color: $pink-900 !important;
}
.bgc-purple-900 {
    background-color: $purple-900 !important;
}
.bgc-deep-purple-900 {
    background-color: $deep-purple-900 !important;
}
.bgc-indigo-900 {
    background-color: $indigo-900 !important;
}
.bgc-blue-900 {
    background-color: $blue-900 !important;
}
.bgc-light-blue-900 {
    background-color: $light-blue-900 !important;
}
.bgc-cyan-900 {
    background-color: $cyan-900 !important;
}
.bgc-teal-900 {
    background-color: $teal-900 !important;
}
.bgc-green-900 {
    background-color: $green-900 !important;
}
.bgc-light-green-900 {
    background-color: $light-green-900 !important;
}
.bgc-lime-900 {
    background-color: $lime-900 !important;
}
.bgc-yellow-900 {
    background-color: $yellow-900 !important;
}
.bgc-amber-900 {
    background-color: $amber-900 !important;
}
.bgc-orange-900 {
    background-color: $orange-900 !important;
}
.bgc-deep-orange-900 {
    background-color: $deep-orange-900 !important;
}
.bgc-brown-900 {
    background-color: $brown-900 !important;
}
.bgc-grey-900 {
    background-color: $grey-900 !important;
}
.bgc-blue-grey-900 {
    background-color: $blue-grey-900 !important;
}

/** A100 **/
.bgc-red-A100 {
    background-color: $red-A100 !important;
}
.bgc-pink-A100 {
    background-color: $pink-A100 !important;
}
.bgc-purple-A100 {
    background-color: $purple-A100 !important;
}
.bgc-deep-purple-A100 {
    background-color: $deep-purple-A100 !important;
}
.bgc-indigo-A100 {
    background-color: $indigo-A100 !important;
}
.bgc-blue-A100 {
    background-color: $blue-A100 !important;
}
.bgc-light-blue-A100 {
    background-color: $light-blue-A100 !important;
}
.bgc-cyan-A100 {
    background-color: $cyan-A100 !important;
}
.bgc-teal-A100 {
    background-color: $teal-A100 !important;
}
.bgc-green-A100 {
    background-color: $green-A100 !important;
}
.bgc-light-green-A100 {
    background-color: $light-green-A100 !important;
}
.bgc-lime-A100 {
    background-color: $lime-A100 !important;
}
.bgc-yellow-A100 {
    background-color: $yellow-A100 !important;
}
.bgc-amber-A100 {
    background-color: $amber-A100 !important;
}
.bgc-orange-A100 {
    background-color: $orange-A100 !important;
}
.bgc-deep-orange-A100 {
    background-color: $deep-orange-A100 !important;
}

/** A200 **/
.bgc-red-A200 {
    background-color: $red-A200 !important;
}
.bgc-pink-A200 {
    background-color: $pink-A200 !important;
}
.bgc-purple-A200 {
    background-color: $purple-A200 !important;
}
.bgc-deep-purple-A200 {
    background-color: $deep-purple-A200 !important;
}
.bgc-indigo-A200 {
    background-color: $indigo-A200 !important;
}
.bgc-blue-A200 {
    background-color: $blue-A200 !important;
}
.bgc-light-blue-A200 {
    background-color: $light-blue-A200 !important;
}
.bgc-cyan-A200 {
    background-color: $cyan-A200 !important;
}
.bgc-teal-A200 {
    background-color: $teal-A200 !important;
}
.bgc-green-A200 {
    background-color: $green-A200 !important;
}
.bgc-light-green-A200 {
    background-color: $light-green-A200 !important;
}
.bgc-lime-A200 {
    background-color: $lime-A200 !important;
}
.bgc-yellow-A200 {
    background-color: $yellow-A200 !important;
}
.bgc-amber-A200 {
    background-color: $amber-A200 !important;
}
.bgc-orange-A200 {
    background-color: $orange-A200 !important;
}
.bgc-deep-orange-A200 {
    background-color: $deep-orange-A200 !important;
}

/** A400 **/
.bgc-red-A400 {
    background-color: $red-A400 !important;
}
.bgc-pink-A400 {
    background-color: $pink-A400 !important;
}
.bgc-purple-A400 {
    background-color: $purple-A400 !important;
}
.bgc-deep-purple-A400 {
    background-color: $deep-purple-A400 !important;
}
.bgc-indigo-A400 {
    background-color: $indigo-A400 !important;
}
.bgc-blue-A400 {
    background-color: $blue-A400 !important;
}
.bgc-light-blue-A400 {
    background-color: $light-blue-A400 !important;
}
.bgc-cyan-A400 {
    background-color: $cyan-A400 !important;
}
.bgc-teal-A400 {
    background-color: $teal-A400 !important;
}
.bgc-green-A400 {
    background-color: $green-A400 !important;
}
.bgc-light-green-A400 {
    background-color: $light-green-A400 !important;
}
.bgc-lime-A400 {
    background-color: $lime-A400 !important;
}
.bgc-yellow-A400 {
    background-color: $yellow-A400 !important;
}
.bgc-amber-A400 {
    background-color: $amber-A400 !important;
}
.bgc-orange-A400 {
    background-color: $orange-A400 !important;
}
.bgc-deep-orange-A400 {
    background-color: $deep-orange-A400 !important;
}

/** A700 **/
.bgc-red-A700 {
    background-color: $red-A700 !important;
}
.bgc-pink-A700 {
    background-color: $pink-A700 !important;
}
.bgc-purple-A700 {
    background-color: $purple-A700 !important;
}
.bgc-deep-purple-A700 {
    background-color: $deep-purple-A700 !important;
}
.bgc-indigo-A700 {
    background-color: $indigo-A700 !important;
}
.bgc-blue-A700 {
    background-color: $blue-A700 !important;
}
.bgc-light-blue-A700 {
    background-color: $light-blue-A700 !important;
}
.bgc-cyan-A700 {
    background-color: $cyan-A700 !important;
}
.bgc-teal-A700 {
    background-color: $teal-A700 !important;
}
.bgc-green-A700 {
    background-color: $green-A700 !important;
}
.bgc-light-green-A700 {
    background-color: $light-green-A700 !important;
}
.bgc-lime-A700 {
    background-color: $lime-A700 !important;
}
.bgc-yellow-A700 {
    background-color: $yellow-A700 !important;
}
.bgc-amber-A700 {
    background-color: $amber-A700 !important;
}
.bgc-orange-A700 {
    background-color: $orange-A700 !important;
}
.bgc-deep-orange-A700 {
    background-color: $deep-orange-A700 !important;
}

/** Black **/
.bgc-black {
    background-color: $black !important;
}
.bgc-black-1 {
    background-color: $black-1 !important;
}
.bgc-black-2 {
    background-color: $black-2 !important;
}
.bgc-black-3 {
    background-color: $black-3 !important;
}
.bgc-black-4 {
    background-color: $black-4 !important;
}

/** White **/
.bgc-white {
    background-color: $white !important;
}
.bgc-white-1 {
    background-color: $white-1 !important;
}
.bgc-white-2 {
    background-color: $white-2 !important;
}
.bgc-white-3 {
    background-color: $white-3 !important;
}
.bgc-white-4 {
    background-color: $white-4 !important;
}