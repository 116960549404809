
///*------------------------------------*\
//    #BASE UNIT SETTINGS
//\*------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Lato');

$base-font:             'Lato', sans-serif  !default;
$base-font-size:        16px                !default;
$base-line-height:      24px                !default;
$base-text-color:       $black              !default;
$base-background-color: $grey-900           !default;
$base-round:            2px                 !default;
$transition-type-cubic: cubic-bezier(0.25, 0.8, 0.25, 1);
$transition-time:       300ms;