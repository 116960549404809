/* 
 * Scroll icon on front section
 */

.scroll {
    border: 3px solid $white;
    border-radius: 10px;
    bottom: 50px;
    display: inline-block;
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 21px;
    /* rolldown dot */
    &:before {
        background-color: $white;
        border-radius: 50%;
        content: '';
        height: 5px;
        left: 0;
        margin: auto;
        position: absolute;
        top: 15%;
        right: 0;
        width: 5px;
    }
    /* scroll text */
    &:after {
        bottom: -22px;
        color: #fff;
        content: 'Scroll';
        font-size: 13px;
        font-weight: 500;
        left: -10px;
        position: absolute;
    }
}

#home {
    .front {
        .bg-image {
            background-image: url('/images/home-background-1800x1200.jpg');
            opacity: 0.8;
        }
        button {
            font-size: 32px;
            font-weight: bold;
            letter-spacing: 2px;
        }
        @include media-breakpoint-down(sm) {
            h1 {
                font-size: 28px;
            }
            button {
                font-size: 22px;
            }
        }
    }
    #caracteristicas {
        .wrap {
            max-width: 1150px;
            .timeline-item {
                .stroke {
                    &:before {
                        background-color: $primary;
                        border: 4px solid $secondary;
                        border-radius: 50%;
                        bottom: 0;
                        content: '';
                        height: 35px;
                        left: 0;
                        margin: auto;
                        position: absolute;
                        right: 0;
                        top: 0;
                        transform: scale(1);
                        transition: all 300ms $transition-type-cubic;
                        width: 35px;
                        z-index: 1;
                    }
                    &:after {
                        background-color: $secondary;
                        content: '';
                        height: 100%;
                        left: 0;
                        margin: auto;
                        position: absolute;
                        right: 0;
                        width: 6px;
                    }
                    &.first {
                        &:after {
                            bottom: 0;
                            height: 50%;
                        }
                    }
                    &.last {
                        &:after {
                            height: 50%;
                            top: 0;
                        }
                    }
                }
                &:hover {
                    .stroke {
                        &:before {
                            transform: scale(1.5);
                        }
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                .timeline-item {
                    text-align: center;
                    .stroke {
                        &:before {
                            display: none;
                        }
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    #registro {
        .bg-image {
            background-image: url('/images/registro-background-688x432.jpg');
        }
        form {
            padding: 15%;
        }
    }
}