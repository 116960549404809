footer {
    @include media-breakpoint-down(sm) {
        text-align: center !important;
        small.text-right {
            text-align: center !important;
        }
    }
    h2 {
        font-size: 28px;
    }
}