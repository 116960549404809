.site-header {
    background-color: transparent;
    z-index: 9;
    img {
        display: none;       
    }
    .btn.mobile {
        display: none;
    }
    .menu-mobile {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        nav {
            display: none;
        }
        .btn.mobile {
            background-color: transparent;
            display: block;
            span {
                border-bottom:2px solid #000;
                border-top:2px solid #000;
                display: block;
                height: 18px;
                width: 25px;
                &:after {
                    border-top: 2px solid #000;
                    content: '';
                    display: block;
                    height: 2px;
                    margin-top: 6px;
                    width: 100%;
                }
            }
        }
        .close {
            right: 0;
        }
        .menu-mobile {
            display: block;
            background-color: $primary;
            bottom: 0;
            margin: 0 !important;
            overflow: auto;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            transform: translate(100%, 0);
            transition: all 600ms $transition-type-cubic;
            > a {
                color: $white !important;
                display: block !important;
                width: 100%;
            }
            &.open {
                transform: translate(0, 0);
            }
        }
    }
    &.position-fixed {
        box-shadow: 3px 0 19px -6px #000;
        background-color: $white;
        img {
            display: block;
            max-width:120px;
        }
        .tc-white {
            color: $blue-grey-900 !important;
        }
    }
}